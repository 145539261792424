<template>
  <div class="box processManage">
    <!-- 文档类别 -->
    <!-- <navi-gation style="font-size: 16px" /> -->
    <el-row  class="common-wrapper" v-if="flag == 1">
      <el-row :gutter="4" class="elRow" style="display: flex">
        <el-col :span="3">
          <el-input
            v-model.trim="search0"
            :placeholder="$t('ContentManage.Documentcate.PlaceDocumentcate')"
            size="small"
            @keyup.enter.native="search"
            @input="(e) => (search0 = validForbid(e))"
          ></el-input
        ></el-col>
        <el-col :span="3"
          ><el-button
            type="primary"
            class="searchbtn"
            size="small"
            @click="search"
            >{{ $t("public.Inquire") }}</el-button
          ></el-col
        >
      </el-row>

      <el-row :gutter="4" class="center">
        <el-col :span="24">
        <el-button-group class="butGruop">
          <el-button type="primary" @click="isflag" size="small">{{
            $t("public.New")
          }}</el-button>
          <el-button
            style="border-radius: 0px 3px 3px 0px"
            @click="delArr(delarr)"
            :type="typeInfo"
            :disabled="!hasSeleted"
            size="small"
            >{{ $t("public.Delete") }}</el-button
          >
        </el-button-group>
      </el-col>
      </el-row>
      <!-- 列表 -->
      <el-row :gutter="4" class="elRow"  style="position: relative;">
        <el-table
          :cell-style="{ padding: '3px 0px' }"
          :header-row-style="{ height: '30px' }"
          ref="tableData"
          :data="tableData"
          :row-key="getRowKeys"
          lazy
          size="mini"
          stripe
          :load="load"
          class="tableClass"
          tooltip-effect="dark"
          style="width: 100%"
          :default-sort="{ prop: 'lastUpdateTime', order: 'descending' }"
          @selection-change="delobj"
          @select="checkChange"
          @sort-change="handleSortChange"
        >
          <el-table-column
            type="selection"
            align="center"
            width="60"
            :selectable="selectEnable"
            :reserve-selection="true"
          >
          </el-table-column>
          <!-- <el-table-column prop="id" label="ID" width="120"> </el-table-column> -->
          <el-table-column
            prop="name"
            :label="$t('ContentManage.Documentcate.Documentcategory')"
            width="180"
            sortable
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            style="color: #6f7982"
            prop="memo"
            :label="$t('ContentManage.Documentcate.Description')"
            width="180"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <!-- <el-table-column
            prop="docCount"
            label="关联文档"
            width="180"
            sortable
            :show-overflow-tooltip="true"
          >
            <template v-slot="{ row }">
              <template v-if="row.docCount == '0'">
                <span>{{ row.docCount }}</span>
              </template>
              <template v-else>
                <span style="color: #e6a23c; cursor: pointer" @click="nameDetail(row)">{{
                  row.docCount
                }}</span>
              </template>
            </template>
          </el-table-column> -->
          <!-- <el-table-column prop="updateTime" label="部门" width="120"> </el-table-column> -->
          <el-table-column
            prop="lastUpdateTime"
            :label="$t('public.ModificationTime')"
            width="180"
            :show-overflow-tooltip="true"
            sortable="custom"
          >
            <template v-slot="{ row }">
              {{ row.lastUpdateTime | dateformat }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('public.Controls')">
            <template slot-scope="scope">
              <span class="action_icon">
                <img
                  @click="nameDetail(scope.row)"
                  src="@/assets/icon_see.png"
                  style="width: 15px; height: 15px; cursor: pointer"
                  :title="$t('ContentManage.Documentcate.Assdocument')"
                />
              </span>
              <span class="action_icon">
                <img
                  @click="edit(scope.row, scope.row.id)"
                  src="@/assets/icon_write.png"
                  style="width: 15px; height: 15px; cursor: pointer"
                  :title="$t('public.Editor')"
                />
              </span>
              <span class="action_icon">
                <img
                  v-if="
                    scope.row.name !==
                    $t('ContentManage.Documentcate.Dailyoffice')
                  "
                  @click="del(scope.row.id)"
                  src="@/assets/icon_del.png"
                  style="width: 15px; height: 15px; cursor: pointer"
                  :title="$t('public.Delete')"
                />
              </span>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <pagination
          :page="currentPage4"
          :limit="pageSize"
          :total="total"
          :pageSizes="[10, 20, 30, 40]"
          v-on:handleChildGetList="handleParentGetList"
        ></pagination>
      </el-row>
    </el-row >
    <!-- 新增 -->
    <div v-else-if="flag == 2">
      <el-row :gutter="16">
        <el-col :span="6">
          <el-form
            size="small"
            :model="form"
            ref="form"
            label-width="170px"
            @submit.native.prevent
            class="demo-ruleForm"
            :rules="rules"
          >
            <el-form-item
              :label="$t('ContentManage.Documentcate.Classname') + '：'"
              prop="name"
            >
              <el-input v-model.trim="form.name" size="small"></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('ContentManage.Documentcate.Description') + '：'"
              prop="memo"
            >
              <el-input
                v-model="form.memo"
                type="textarea"
                :rows="6"
                show-word-limit
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="add('form')" size="small">{{
                $t("public.Save")
              }}</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <!-- <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="add('form')" size="small" class="save"
          >保存</el-button
        >
      </div> -->
    </div>
    <!-- 编辑 -->
    <div v-else>
      <el-row :gutter="16">
        <el-col :span="6">
          <el-form
            size="small"
            :model="form"
            ref="form"
            label-width="170px"
            @submit.native.prevent
            class="demo-ruleForm"
            :rules="rules"
          >
            <el-form-item
              :label="$t('ContentManage.Documentcate.Classname') + '：'"
              prop="name"
            >
              <el-input v-model.trim="form.name"></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('ContentManage.Documentcate.Description') + '：'"
              prop="memo"
            >
              <el-input
                v-model="form.memo"
                type="textarea"
                :rows="6"
                show-word-limit
              >
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="save('form')" size="mini">{{
                $t("public.Save")
              }}</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>
    <!-- 关联文档详情 -->
    <Eldialog
      @handleClose="handleClose"
      :title="titleInfo"
      :dialogVisible="installFlag"
      :num="'470px'"
      :width="'900px'"
    >
      <categoryDetail :cateID="cateID" />
    </Eldialog>
    <!-- 保存成功的弹框 -->
    <dialog-info
      :dialogInfo="Successdialog"
      @handleClose="handleClose"
      @determine="determine"
      :titleInfo="seccess"
      :width="'240px'"
    />
  </div>
</template>
<script>
import pagination from "@/components/pagination/page";
import naviGation from "@/components/hearder/index";
import dialogInfo from "@/components/promptMessage/index";
import categoryDetail from "./components/categoryDetail.vue";
// 弹框组件
import Eldialog from "@/components/elDialog";
export default {
  components: { pagination, naviGation, dialogInfo, Eldialog, categoryDetail },

  data() {
    return {
      typeInfo: "info", // 删除按钮默认灰色
      groupFullNameShowByIndex: "", //用户部门截取的下标
      // 校验
      name: "",
      memo: "",
      rules: {
        name: [
          { required: true, message: "请输入类别名称", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              if (/^[\u4e00-\u9fa5\w]+$/.test(value) == false) {
                callback(new Error("不支持空格及特殊字符"));
              } else {
                callback();
              }
            },
            trigger: "blur", //这里还有blur触发方法； 如果设为none，那么只有this.$refs[formName]['validate']时才会验证
          },
          { min: 1, max: 8, message: "最长输入 8 个字符", trigger: "blur" },
        ],
        memo: [
          { min: 1, max: 200, message: "最长输入 200 个字符", trigger: "blur" },
        ],
      },
      dataFrom: {},
      dialogVisible: false,
      textarea: "",
      enable1: "0",
      enable2: "1",
      tableData: [],
      multipleSelection: [],
      //   分页
      currentPage4: 1, //当前页
      pageSize: 10, //每页显示条数
      total: 0,
      flag: 1,
      id: "",
      search0: "",
      form: {
        name: "", //名称
        memo: "", //描述
        docCount: "", //级别，1-低，2-中，3-高
      },
      Successdialog: false, //控制弹出
      Sencond: 5, //设置初始倒计时
      isDisabled: false,
      time: null,
      seccess: "",
      delarr: [], //删除
      // 全选弹框
      isCheckShow: false,
      // 全选框双向绑定
      checked: false,
      total1: 0,
      tableAn1: [],
      titleInfo: "",
      installFlag: false,
      sorts: "lastUpdateTime desc",
      input: "", //弹框的查询
      isnlist: [],
      // 排序
      page: {
        orderColume: "lastUpdateTime", //排序字段
        orderRule: "DESC", //排序规则ASC、DESC
      },
      cateID: 0,
    };
  },
  computed: {
    hasSeleted() {
      return this.delarr && this.delarr.length;
    },
  },

  watch: {},
  created() {},

  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      "groupFullNameShowByIndex"
    );
    this.getDataList();
  },

  methods: {
    //仅对 type=selection 的列有效，类型为 Function，Function 的返回值用来决定这一行的 CheckBox 是否可以勾选
    selectEnable(row, rowIndex) {
      if (row.name == this.$t("ContentManage.Documentcate.Dailyoffice")) {
        return false;
      } else {
        return true;
      }
    },

    // 列表
    async getDataList() {
      var param = {
        currentPage: this.currentPage4,
        pageSize: this.pageSize,
        searchName: this.search0,
        orderColume: this.page.orderColume,
        orderRule: this.page.orderRule,
        // orderColume: "lastUpdateTime",
        // orderRule: "DESC",
        // manage: "1",
        // pageNumber: this.currentPage4, //页数
        // rowNumber: this.pageSize, //行数
        // sort: this.sorts, //排序, desc-倒序， asc-正序, 支持字段：r.name(框架名称), r.updateTime(修改时间)
        // where: {
        //   //查询条件
        //   search0: this.search0,
        // },
      };
      // console.log(param);
      const res = await this.$axios.post(
        // '/api/documentCategory/paginate.do',
        "/httpServe/documentCategory/getDataInfo",
        param,
        true
      );
      console.log(res);
      this.tableData = res.data.content;
      // console.log(this.tableData);
      this.total = res.data.total;
    },
    //排序
    handleSortChange(column) {
      if (column.order === "ascending") {
        this.page.orderRule = "ASC";
      } else if (column.order === "descending") {
        this.page.orderRule = "DESC";
      }
      this.page.orderColume = column.prop;
      if (column.prop == "lastUpdateTime") {
        if (column.order == "ascending") {
          this.sorts = "lastUpdateTime asc";
        } else {
          this.sorts = "lastUpdateTime desc";
        }
      } else if (column.prop == "docCount") {
        if (column.order == "ascending") {
          this.sorts = "docCount asc";
        } else {
          this.sorts = "docCount desc";
        }
      } else if (column.prop == "name") {
        if (column.order == "ascending") {
          this.sorts = "name asc";
        } else {
          this.sorts = "name desc";
        }
      }
      this.getDataList();
    },
    // 关联文档详情
    async nameDetail(row) {
      this.cateID = row.id;
      console.log(row.id, ".id.id");
      this.activeName = "first";
      // let params = {
      //   docCategoryId: ID,
      //   parentId: -1,
      //   currentPage: this.currentPage4,
      //   pageSize: this.pageSize,
      //   // sort: undefined,
      //   // where: {
      //   //   search0: "",
      //   // },
      // }
      // console.log(params)
      // this.titleInfo = '关联文档'
      // // const res = await this.$axios.post("/api/document/paginate.do", params, true);
      // const res = await this.$axios.post(
      //   '/httpServe/document/getDataInfo',
      //   params,
      //   true
      // )
      // console.log(res)
      // this.tableAn1 = res.data.content
      // this.isnlist = res.data.content
      // this.total1 = res.data.total
      this.titleInfo = this.$t("ContentManage.Documentcate.Assdocument");
      this.installFlag = true;
    },

    // sing() {
    //   // alert("123")
    //   this.input = "";
    // },
    // 新增
    isflag() {
      this.flag = 2;
    },
    add(form) {
      this.$refs[form].validate(async (valid) => {
        if (valid) {
          // 请求接口
          var param = {
            // ids: this.id,
            name: this.form.name, //名称
            memo: this.form.memo, //描述
          };
          // console.log(param);
          const res = await this.$axios.post(
            "/httpServe/documentCategory/save",
            param,
            true
          );
          // const res = await this.$axios.post("api/documentCategory/save.do", param, true);
          if (res.data == "-1") {
            this.$message({
              message: this.$t("ContentManage.Documentcate.Adderror"),
              type: "error",
              offset: 100,
            });
            return false;
          } else {
            // this.Successdialog = true
            // this.seccess = '保存成功'
            this.$message({
              message: this.$t("public.SuccessfullySaved"),
              type: "success",
              offset: 100,
            });
            this.flag = 1;
            this.search0 = "";
            // this.getDataList();
          }
          this.getDataList();
          (this.form = {
            name: "", //名称
            memo: "", //描述
            level: "3", //级别，1-低，2-中，3-高
          }),
            (this.flag = 1);
        } else {
          // console.log("error");
          return false;
        }
      });
    },
    // 回显
    edit(info) {
      info.level = "" + info.level;
      this.flag = 3;
      this.id = info.id;
      console.log(this.id, "id");
      this.form = info;

      // this.form.memo = info.memo;
    },
    // 编辑后保存
    save(form) {
      this.$refs[form].validate(async (valid) => {
        if (valid) {
          // 请求接口
          var param = {
            ids: [this.id], //ID (新增时无)
            name: this.form.name, //名称
            memo: this.form.memo, //描述
          };
          console.log(param, "编辑参数");
          const res = await this.$axios.post(
            "/httpServe/documentCategory/update",
            param,
            true
          );
          console.log(res, "编辑");
          if (res.data == "-1") {
            this.$message({
              message: this.$t("ContentManage.Documentcate.Adderror"),
              type: "error",
              offset: 100,
            });
            return false;
          } else {
            // this.Successdialog = true
            // this.seccess = '保存成功'
            this.$message({
              message: this.$t("public.SuccessfullySaved"),
              type: "success",
              offset: 100,
            });
            this.flag = 1;
            this.search0 = "";
            // this.getDataList();
          }
          // this.Successdialog = true;
          // this.seccess = "保存成功";
          // this.time = setInterval(() => {
          //   this.Sencond -= 1;
          // }, 1000);
          this.getDataList();
          // this.flag = 1;
        } else {
          // console.log("error");
          return false;
        }
      });
    },
    getRowKeys(row) {
      return row.id;
    },

    // 删除
    delobj(obj) {
      this.delarr = obj;
      this.typeInfo = "primary";
      if (this.delarr.length == 0) {
        this.typeInfo = "info";
      }
    },
    delArr(obj) {
      var ids = [];
      obj.forEach((item) => {
        ids.push(item.id);
      }); // 请求接口
      // console.log(ids);
      var parma = { ids: ids, deleteAll: 2 };

      this.$confirm(
        this.$t("ContentManage.Documentcate.DeleteOK"),
        this.$t("public.PromptMessage"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          customClass: "persdsd",
          type: "warning",
        }
      )
        .then(async () => {
          const res = await this.$axios.post(
            "/httpServe/documentCategory/delete",
            parma,
            true
          );
          if (res.data == "-1") {
            this.$message({
              message: this.$t("ContentManage.Documentcate.Deletionfailure"),
              type: "error",
              offset: 100,
            });
            return;
          }
          // this.Successdialog = true
          // this.seccess = '删除成功'
          this.$message({
            message: this.$t("public.SuccessfullyDeleted"),
            type: "success",
            offset: 100,
          });
          this.$refs.tableData.clearSelection();
          this.currentPage4 = 1;
          this.getDataList();
        })
        .catch(() => {
          return false;
        });
    },
    del(id) {
      // 请求接口
      var parma = { ids: [id], deleteAll: 2 };

      this.$confirm(
        this.$t("ContentManage.Documentcate.DeleteOK"),
        this.$t("public.PromptMessage"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          customClass: "persdsd",
          type: "warning",
        }
      )
        .then(async () => {
          const res = await this.$axios.post(
            "/httpServe/documentCategory/delete",
            parma,
            true
          );
          // console.log(res);
          if (res.data == "-1") {
            this.$message({
              message: this.$t("ContentManage.Documentcate.Deletionfailure"),
              type: "error",
              offset: 100,
            });
            return;
          }
          // this.Successdialog = true
          // this.seccess = '删除成功'
          this.$message({
            message: this.$t("public.SuccessfullyDeleted"),
            type: "success",
            offset: 100,
          });
          // this.time = setInterval(() => {
          //   this.Sencond -= 1;
          // }, 1000);
          this.$refs.tableData.clearSelection();
          this.currentPage4 = 1;
          this.getDataList();
        })
        .catch(() => {
          return false;
        });
    },
    // 选中未选中
    checkChange(selection, row) {
      selection.forEach((item) => {
        this.delarr.push(item.id);
      });
    },
    // 弹框
    handleClose() {
      this.Successdialog = false; //提示消息  关闭
      this.installFlag = false;
      this.dialogVisible = false; //提示消息  关闭
      this.input = ""; //弹框输入框清空
      // this.$refs.child.sing();
    },
    determine() {
      this.Successdialog = false; //提示消息  关闭
    },
    // 查询
    search() {
      if (this.search0.length > 0) {
        this.currentPage4 = 1;
      }
      this.getDataList();
    },
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.currentPage4 = page;
      this.pageSize = limit;
      // 调用查询方法
      this.getDataList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getDataList();
    },
    load() {},
    //全选遮罩层
    maskLayer() {
      this.isCheckShow = true;
    },
    // 鼠标移出弹框
    leave() {
      this.isCheckShow = false;
    },
    //当前页全选
    curSelection() {
      this.$refs.tableData.toggleAllSelection();
    },
    //所有页全选
    // toggleAllSelection() {
    //   this.$refs.tableData.toggleAllSelection();
    // },
    //取消全选
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          row.forEach((item) => {
            item.checked = true;
          });
          this.$refs.tableData.toggleRowSelection(row);
        });
      } else {
        this.$refs.tableData.clearSelection();
      }
      this.isCheckShow = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.common-wrapper {
  width: 100%;
  height: 100%;
  padding: 10px 10px 0px 10px !important; //页面最外层上右左10
  min-width: 1500px;
  font-family: Microsoft YaHei;
  .elRow {
    margin-bottom: 10px;
  }
}
.breadtitle {
  margin-left: 3px;
  border-bottom: 1px solid #cdd1dd;
  height: 40px;
  line-height: 40px;
  color: #6d7a82;
  font-size: 16px;
}

::v-deep input::-webkit-input-placeholder {
  // color: red;
  font-size: 12px;
}


.center {
  margin: 10px 0;
  justify-content: flex-start;
}

.save {
  margin: 4px 0 102px 200px;
}

.el-form {
  margin-top: 25px;
}

.el-icon-edit,
#el-icon-close {
  border: 1px solid;
  padding: 3px;
  border-radius: 5px;
  margin-right: 5px;
  color: #4787de;
  font-weight: bold;
}

// #success{
//   // height: 100px;
// }


.el-button + .el-button {
  margin-left: 0px;
}

.el-checkbox {
  margin-right: 10px;
}

.el-radio,
.el-radio__input {
  margin-left: 10px;
}

.el-checkbox,
.el-checkbox__input {
  margin-left: 3px;
}

.el-select .el-input {
  width: 120px;
}

.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.el-checkbox:last-of-type {
  margin-left: 12px;
}

.el-dialog .el-dialog__body {
  padding: 5px 12px 24px 20px !important;
  //height: 100px;
  overflow: auto;
  margin-right: 12px;
}

// .center {
//   width: 100%;
// margin-left: 10px;
// margin-right: 10px;
// margin-top: 10px;
/* display: flex; */
/* justify-content: space-between; */
//   justify-content: flex-start;
// }
.el-form-item__label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #606266;
  height: 14px;
  line-height: 20px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
}

.el-form-item__content {
  line-height: 36px;
  position: relative;
  font-size: 14px;
}

.save[data-v-42c2d864] {
  margin: 21px 0 102px 200px !important;
  margin-bottom: 110px;
}

::v-deep .el-form-item {
  margin-bottom: 17px !important;
}

::v-deep .el-textarea__inner {
  font-family: Microsoft YaHei;
  font-size: 12px;
}

::v-deep .el-input__inner {
  font-family: Microsoft YaHei;
  font-size: 12px;
}
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
.butGruop > .el-button:not(:last-child) {
  margin-right: 0px !important;
}
</style>
<style lang="scss">
.save[data-v-42c2d864] {
  margin: 21px 0 102px 200px !important;
  margin-bottom: 110px;
}
</style>
