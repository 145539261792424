import { render, staticRenderFns } from "./documentcategory.vue?vue&type=template&id=5501a5be&scoped=true&"
import script from "./documentcategory.vue?vue&type=script&lang=js&"
export * from "./documentcategory.vue?vue&type=script&lang=js&"
import style0 from "./documentcategory.vue?vue&type=style&index=0&id=5501a5be&prod&lang=scss&scoped=true&"
import style1 from "./documentcategory.vue?vue&type=style&index=1&id=5501a5be&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5501a5be",
  null
  
)

export default component.exports