<template>
  <div>
    <el-input v-model.trim="input" size="small" style="width: 200px; margin-bottom: 10px" :placeholder="$t('ContentManage.Documentcate.Placeholder')" @keyup.enter.native="searchDev">
      <i style="cursor: pointer" class="el-icon-search el-input__icon" slot="suffix" @click="searchDev">
      </i>
    </el-input>
    <!-- <el-table :cell-style="{padding:'3px 0px'}" :header-row-style="{height:'30px'}" :data="tableAn1" style="width: 100%" tooltip-effect="dark"> -->
    <el-table :cell-style="{padding:'3px 0px'}" :header-row-style="{height:'30px'}" stripe ref="contentList" :data="tableAn1" size="mini" style="width: 100%" class="tableClass">
      <el-table-column :label="$t('ContentManage.Documentcate.Name')" prop="name" width="150" :show-overflow-tooltip="true">
        <template v-slot="{ row }">
          {{ row.name }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('public.Section')" prop="groupFullName" width="150">
        <template v-slot="{ row }">
          <span v-if="row.groupFullName">
            {{ row.groupFullName.substr(groupFullNameShowByIndex) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('ContentManage.Documentcate.Creator')" prop="userName" width="150">
        <template v-slot="{ row }">
          {{ row.userName }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('public.ModificationTime')" prop="lastUpdateTime" :show-overflow-tooltip="true">
        <template v-slot="{ row }">
          {{ row.lastUpdateTime | dateformat }}
        </template>
      </el-table-column>
    </el-table>
    <!-- <p style="margin-top: 10px">
            共有<span>{{ total1 }}</span>条记录
        </p> -->
    <pagination :page="currentPage" :limit="pageSize" :total="total" :pageSizes="[10, 20, 30, 40]" v-on:handleChildGetList="handleParentGetList"></pagination>
  </div>
</template>
<script>
import pagination from '@/components/pagination/page'
export default {
  components: { pagination },
  props: {
    cateID: {
      type: Number
    }
  },
  data() {
    return {
      currentPage: 1,
      pageSize: 10,
      total: 0,
      tableAn1: [],
      input: ''
    }
  },
  watch: {
    cateID: {
      handler(newVal, oldVal) {
        console.log(oldVal, '旧值1')
        console.log(newVal, '新值2')
        this.tabID = newVal
        setTimeout(() => {
          this.searchDev()
        }, 200)
      },
      deep: true,
      immediate: true
    }
  },
  created() {},
  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      'groupFullNameShowByIndex'
    )
    // this.searchDev()
  },
  methods: {
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.currentPage = page
      this.pageSize = limit
      // 调用查询方法
      this.searchDev()
    },
    // 弹框查询
    async searchDev() {
      let params = {
        docCategoryId: this.cateID,
        // id: this.cateID,
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        name: this.input
        // sort: undefined,
        // where: {
        //   search0: "",
        // },
      }
      console.log(params)

      // const res = await this.$axios.post("/api/document/paginate.do", params, true);
      const res = await this.$axios.post(
        '/httpServe/document/getDataInfo',
        params,
        true
      )
      console.log(res)
      this.tableAn1 = res.data.content
      this.total = res.data.total
    }
  }
}
</script>

<style lang='scss' scoped>
::v-deep .el-table__empty-text {
  line-height: 60px;
  width: 52%;
  color: #909399;
}
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
</style>
